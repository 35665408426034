<template>
    <div class="group" :class="[size]">
      <input v-if='mutType === "tel"' :class="[size]" :type="mutType" onfocus="this.removeAttribute('readonly');" autocorrect="off" spellcheck="false"
             :value="value" @input="changeValue($event.target.value,$event)" required v-mask="'+7(###)###-##-##'">
      <input v-else :type="mutType" :placeholder="placeholder" :class="[size]" :value="value" @input="changeValue($event.target.value,$event)">

      <a v-if="iconClass && !isShowPassword" @click="toggleShowPassword"><span :class="iconClass"></span></a>
        <a v-if="iconClass === 'icon-hide' && isShowPassword" @click="toggleShowPassword"><span class="icon-show"></span></a>
    </div>
</template>

<script>
import { mask } from 'vue-the-mask'


    export default {
        name: "ProfileInput",
        props:{
            placeholder:String,
            size: String,
            value: String,
            type: String,
            isTrimEnable : Boolean,
            isRussianSymbolsDisabled: Boolean,
            iconClass: String,

        },
        watch:{
            type:{
                immediate:true,
                deep: true,
                handler(newVal){
                    this.mutType = newVal
                }
            }
        },
      directives: {
        mask
      },
        data(){
            return {
                isShowPassword: false,
                mutType : 'input'
            }
        },
        methods: {
            changeValue(value,ev) {
                if (this.isTrimEnable) {
                    value = value.replace(/ /g,'')
                }
                if (this.isRussianSymbolsDisabled){
                    value = value.replace(/[^a-zA-Z0-9]+/gm,'')
                }
                ev.target.value = value
                console.log(value)
                this.$emit('input', value)
            },

            toggleShowPassword(e){
                console.log(e)
                if (e.target.classList.contains('icon-show') || e.target.classList.contains('icon-hide')){
                    this.isShowPassword = !this.isShowPassword
                    if (this.isShowPassword){
                        this.mutType = 'input'
                    } else {
                        this.mutType = 'password'
                    }
                }
            },
        }
    }

</script>

<style scoped lang="scss">
    .group {
        position: relative;
        width: 325px;
    }

    a{
        position: absolute;
        right: 25px;
        top: 17px;

        span{
            opacity: .4;

            &.icon-hide{
                font-size: 12px;
                cursor: pointer;
            }

            &.icon-user{
                font-size: 12px;
            }
        }

        span:before{
            color: #141213;
        }

    }

    .icon-show{
        font-size: 9px;
        cursor: pointer;
    }

    input{
        height: 46px;
        padding: 0;
        padding-left: 10px;
        font-family: Roboto, "sans-serif";
        font-size: 17px;
        color: black;
        letter-spacing: 0px;
        background-color: white;
        border-radius: 8px;
        border: 0;
        outline: 0;


        &::placeholder{
            color: #808080;
          opacity: .5;
        }
    }

    .md{
        width: calc(335px - 10px);
    }

    .xs{
        height: 46px;
        width: 46px;
        border-radius: 8px;
    }

    .text-center{
        input{
            text-align: center;
            padding-left: 0;
        }
    }

    .group.xs{
        width: auto;
    }
</style>
