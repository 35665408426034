

























import {Component, Vue} from "vue-property-decorator";
import CircleImageButton from "@/components/CircleImageButton.vue";
import {ActiveCard} from "@/entity/ActiveCard";

@Component({
    components: {CircleImageButton}
})
export default class WarningUnionAccountModal extends Vue{
    get width(){
        return "309px"
    }

    get height(){
        return "420px"
    }

    onClose(){
        this.$modal.hide("WarningUnionAccountModal");
    }

    onDecline(){
        this.onClose();
    }

    onConfirm(){
        this.onClose();
        this.$emit("confirm");
    }
}
